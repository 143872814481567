import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { Chapter1Component } from './pages/chapter-1/chapter-1.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NavbarComponent } from './component/navbar/navbar.component';
import { ChevronComponent } from './component/chevron/chevron.component';
import { Chapter2Component } from './pages/chapter-2/chapter-2.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DrawerComponent } from './component/drawer/drawer.component';
import {MatIconModule, MatSnackBarModule} from '@angular/material';
import { SignatureComponent } from './component/signature/signature.component';
import {HttpClientModule} from '@angular/common/http';
import {EmailService} from './service/email/email.service';
import { ProjectComponent } from './pages/project/project.component';
import { MedallionComponent } from './component/medallion/medallion.component';
import { SocialComponent } from './component/social/social.component';
import { CloseCrossComponent } from './component/close-cross/close-cross.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Chapter1Component,
    NavbarComponent,
    ChevronComponent,
    Chapter2Component,
    ContactComponent,
    DrawerComponent,
    SignatureComponent,
    ProjectComponent,
    MedallionComponent,
    SocialComponent,
    CloseCrossComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatIconModule,
    HttpClientModule,
    MatSnackBarModule

  ],
  providers: [EmailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
