import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {fadeAnimationQuick, fadeOutAnimationQuick} from '../../animations/animations';


@Component({
  selector: 'project-page',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  animations:   [fadeAnimationQuick, fadeOutAnimationQuick]
})
export class ProjectComponent implements OnChanges {
  @Input()
  private project;
  @Input()
  public displayed;
  @Output()
  public closePage = new EventEmitter();

  private projectDetails = [];
  public mainImage = {};

  public galleryClass = "gallery-section";

  constructor() { }

  ngOnChanges() {
    if(this.project.array) {
      this.projectDetails = {...this.project.array};
      this.mainImage = this.projectDetails['images'][0];
    }
  }



}
