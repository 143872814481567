import { NgModule } from '@angular/core';
import {RouterModule , Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {Chapter1Component} from './pages/chapter-1/chapter-1.component';
import {Chapter2Component} from './pages/chapter-2/chapter-2.component';
import {ContactComponent} from './pages/contact/contact.component';
import {ProjectComponent} from './pages/project/project.component';

const routes: Routes = [
  {path: '', component: HomeComponent, data: {state: 'home'}},
  {path: 'chapter1', component: Chapter1Component, data: {state: 'chapter1'}},
  {path: 'chapter2', component: Chapter2Component, data: {state: 'chapter2'}},
  {path: 'contact', component: ContactComponent, data: {state: 'contact'}},
  {path: 'projects', component: ProjectComponent, data: {state: 'projects'}},
  {path: '**', component: HomeComponent, data: {state: 'home'}},
];

@NgModule({
  exports: [RouterModule],
  imports: [ RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
