import {Component, Input, OnInit} from '@angular/core';
import {fadeAnimationQuick, fadeOutAnimationQuick} from '../../animations/animations';

@Component({
  selector: 'app-medallion',
  templateUrl: './medallion.component.html',
  styleUrls: ['./medallion.component.css'],
  animations:   [fadeAnimationQuick, fadeOutAnimationQuick]
})
export class MedallionComponent implements OnInit {
  @Input()
  project: {};

  @Input()
  title: {};


  public building = false;
  constructor() {
  }

  ngOnInit() {
    if(!this.title){
      this.title = this.project['title'];
    }

    //Building sections
    if(this.project['building']){
      this.building = true;
    }
  }

  imageProject(): string {
    if (this.project['array'] && this.project['array']['images']) {
      return this.project['array']['images'][0].src;
    }
    return '';
  }
}
