import {Component, OnInit} from '@angular/core';
import {themes} from '../../constante';
import {Router} from '@angular/router';

@Component({
  selector: 'app-chapter-2',
  templateUrl: './chapter-2.component.html',
  styleUrls: ['./chapter-2.component.css']
})
export class Chapter2Component implements OnInit {
  arrayThemes = themes;
  public mainId: number;
  public themeClicked = {clicked: false, theme: 0};
  public projectClicked = {clicked: false, project: {}};


  constructor(private router: Router) {
  }

  ngOnInit() {
   document.getElementById('fixed-top-navbar').style.zIndex = '10';
  }


  animationImage(param) {
    const currentId = Number(param.target.children[0].id);
    if (currentId || currentId === 0) {
      for (const image of this.arrayThemes) {
        if (image.id === currentId) {
          this.mainId = currentId;
        }
      }
    } else {
      this.mainId = null;
    }
  }

  goToTheme(id: number) {
    if (this.themeClicked.clicked && this.themeClicked.theme === id) {
      this.themeClicked.clicked = false;
      this.themeClicked.theme = 0;
    } else {
      this.themeClicked.clicked = true;
      this.themeClicked.theme = id;
    }
  }

  goToProject(project: Object) {
    //avoid to display building sections
    if(!project['building']) {
      this.projectClicked = {clicked: true, project: project};
    }
  }

  closeProjectPage(){
    this.projectClicked = {clicked: false, project: {}}
  }

  isMobile() {
    //TODO upgrade this code, very naive
    return window.innerWidth < 800;
  }
}
