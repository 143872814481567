import {Injectable} from '@angular/core';
import {dev} from '../../constante';

@Injectable()
export class EmailService {
  private urlSendEmail = dev.scriptEmail;

  constructor() {
  }

  validEmail(email) { // see:
    const reg = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return reg.test(email);
  }

  sendEmail(data: Object) {
    if (!this.validEmail(data['email']) || (data['message'] === '')) {   // if email is not valid show error
      return false;
    } else {
      // TODO show when it's done && rendre email beau
      const url = this.urlSendEmail;
      console.log(url);
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      // xhr.withCredentials = true;
      // xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      // url encode form data for sending as post data
      const encoded = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
      }).join('&');
      xhr.send(encoded);
      return true;
    }
  }




}
