import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-chevron',
  templateUrl: './chevron.component.html',
  styleUrls: ['./chevron.component.css']
})
export class ChevronComponent implements OnInit {
  @Input()
  path: string;
  @Input()
  color: string;
  @Input()
  direction: string;

  @Output()
  clickEvent = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

}
