import {
  aquabulleBD,
  boisDeLibremont, coconDu7ieme,
  danseEntreLesEaux,
  entreTerreEtMer,
  estranDeLaSeine, pailloteFleurie,
  sentierInterpretation,
  sphagnaceae
} from './projects-constantes';




export class ConstanteName {
  public static title = 'Green Eye Paysage';
  public static lastName = 'Zago';
  public static firstName = 'Matthieu';
  public static pathLogo = 'assets/logo.png';
  public static goldColor = '#d19a43';
  public static profilePicture = 'assets/photo.gif';
}

export const dev = {
  linkedin: {url: 'https://www.linkedin.com/in/arnaud-zago/', label: 'Arnaud Zago'},
  scriptEmail: 'https://script.google.com/macros/s/AKfycbzkJASz9q7sOoZxKi0qn6wgBThyqQ7ghG3f3teDeaBatthh0kc/exec'
};

export const contact = {
  email: {url: 'matthieu.zago@orange.fr', label: 'matthieu.zago@orange.fr'},
  linkedin: {url: 'https://www.linkedin.com/in/matthieu-zago-5aa912b9/', label: 'Matthieu Zago'},
  facebook: {url: 'https://www.facebook.com/agenceGEP/', label: 'agenceGEP'},
  instagram: {url: 'https://www.instagram.com/greeneyepaysage/', label: 'greeneyepaysage'},
};

export const text = {
  chapter1: {
    p1: `Né à Bordeaux, sous les festivités colorées du dernier jour de Décembre 91, rappelles toi cette douce campagne vallonnée et
    silencieuse que tu arpentais comme un immense terrain de jeu. Scruter le moindre buisson, écouter le craquement lent des branches
    sous le vent, inconsciemment, et pourtant tu n’en gardes pas moins des images précises qui t’ont marqué en profondeur et
    ont plus tard alimenté tes écrits.`,
    p2: `Toujours rêveur, tu employais aussi nombre de tes journées à dessiner. Depuis tout petit déjà, tu ne gribouillais pas uniquement le
     sujet - bien souvent préhistorique - mais également son environnement auquel tu attachais tout autant d’importance. Bac S (Sciences) en
      poche. Deux premières années à la fac de médecine. Tu savais pourtant que ce n’était pas ta voie, non ?`,
    p3: `« Oui, je le savais, mais je n’ai jamais aimé les raccourcis. »`,
    p4: `Tu entames finalement tes études de paysagiste - tes vraies - à Saintes, au lycée agricole Georges Desclaude. Dire que tu as été
    enthousiaste est un euphémisme. Plus que la vie d’étudiant proche de l’Atlantique, tes enseignants t’ont ouvert la porte à plus ample, à
     plus libérateur. L’école de paysage de Versailles ? Inconnue jusque là. Quatre années sont passées en une. Tes chères études
     aboutissent en 2017, diplômé de l’ENSP de Versailles avec le voeu de ton jury que ton travail soit publié. ça y est, tu es paysagiste.`,
    p5: `« Je suis paysagiste, oui, mais je suis avant tout dans une déambulation permanente de l’esprit et du corps, jusqu’au bout du
    crayon. Une sorte de randonnée maladive qui m’exalte et m’interroge sur toutes les petites choses de la vie. Le seul aboutissement qui
    compte pour moi, est de parcourir le plus long chemin, en espérant qu’il ne mène jamais nul part. »`
  }
};



export const themes = [
  {
    src: 'assets/theme/ConduireLeVivant.png', label: 'Conduire le vivant', id: 0,
    projects: [
      {title: `Bois de libremont - L\'évidence du terrain`, array: boisDeLibremont},
      {title: `Sentier d\'interprétation - Mont Brouilly`, array: sentierInterpretation},
    ]
  },
  {
    src: 'assets/theme/HistoireDeau.png', label: 'Histoire d\'eau', id: 1,
    projects: [
      {title: 'Danse entre les eaux', array: danseEntreLesEaux},
      {title: `Entre Terre et Mer s\'étendent les havres`, array: entreTerreEtMer},
      {title: 'L\'estran de la Seine - 2014', array: estranDeLaSeine},
      {title: 'Sphagnaceae plateau de Saclay', array: sphagnaceae},
    ]
  },
  {src: 'assets/theme/Aquabulle.png', label: 'Aquabulle', id: 2,
    projects: [
      {title: 'Dérive et apesanteur, à la conquête du proche espace sous-marin - Monaco', array: aquabulleBD, building: true},
    ]},
  {src: 'assets/theme/Jardin.jpg', label: 'Jardin de Malachite', id: 3,
    projects: [
      {title: 'La paillote fleurie', array: pailloteFleurie},
      {title: `Le cocon du 7ième`, array: coconDu7ieme},
    ]
  },
];

export const error = {
  email: '* Les champs doivent être valides et remplis.',
  error: '* Une erreur est survenue, essayez plus tard.'
};

export const success = {
  email: 'Message envoyé ! Merci !'
};


