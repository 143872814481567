import {Component, OnInit} from '@angular/core';
import {fadeAnimationQuick, fadeOutAnimationQuick} from '../../animations/animations';
import {contact} from '../../constante';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css'],
  animations:   [fadeAnimationQuick, fadeOutAnimationQuick]
})
export class SocialComponent implements OnInit {

  socialSelected = false;
  time = 0;
  contact = contact;


  constructor() {
  }

  ngOnInit() {
  }

  animationSocial(event) {
    if (event.type === 'click') {
      if (this.socialSelected) {
        this.reset('');
      } else {
        this.socialSelected = !this.socialSelected;
      }

    }else {
      this.socialSelected = true;
    }
    if (this.socialSelected && this.time === 0) {
      setTimeout(() => this.time++, 500);
      setTimeout(() => this.time++, 1000);
      setTimeout(() => this.time++, 1500);
    }
  }

  reset(event) {
    setTimeout(() => this.time--, 500 );
    setTimeout(() => this.time--, 1000 );
    setTimeout(() => this.time--, 1500 );
    setTimeout(() => { this.socialSelected = false; this.time = 0; }, 2000);
  }

}
