import {Component, OnInit} from '@angular/core';
import {ConstanteName, text} from '../../constante';

@Component({
  selector: 'app-chapter-1',
  templateUrl: './chapter-1.component.html',
  styleUrls: ['./chapter-1.component.css'],
})
export class Chapter1Component implements OnInit {
  text = text.chapter1;
  profilePicture = ConstanteName.profilePicture;

  constructor() {
  }

  ngOnInit() {
  }


}
