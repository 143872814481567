import {Component} from '@angular/core';
import {routerTransition} from './animations/animations';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
declare const ga: any;

@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="getState(o)!='home'">
      <app-navbar></app-navbar>
      <app-drawer></app-drawer>
    </ng-container>
    <main [@routerTransition]="getState(o)">
      <router-outlet #o="outlet">
      </router-outlet>
    </main>
  `,
  styleUrls: ['./app.component.css'],
  animations: [routerTransition]
})
export class AppComponent {
  constructor(private router: Router) {
    if(environment.production) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          ga('create', 'UA-116173273-3', 'auto');
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      });
    }
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
