import { Component, OnInit } from '@angular/core';
import {ConstanteName} from '../../constante';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constName = ConstanteName;

  constructor() { }

  ngOnInit() {
  }

  openNav() {
    if (document.getElementById('drawer').style.width === '100%') {
      document.getElementById('drawer').style.width = '0';
      document.getElementById('drawer').style.opacity = '0';
      document.getElementById('drawer').style.color = 'black';
    }else {
      document.getElementById('drawer').style.width = '100%';
      document.getElementById('drawer').style.opacity = '1';
      document.getElementById('drawer').style.color = 'white';

    }

  }

}
