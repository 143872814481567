export const danseEntreLesEaux = {
  images: [{src: 'assets/projects/HistoireDeau/DanseEntreLesEaux/aleas-modere.jpg', label: `Carte de la Grande Motte présentant les aléas d'inondation de modérés à forts`},
    {src: 'assets/projects/HistoireDeau/DanseEntreLesEaux/carte-lido.jpg', label: 'Carte des paysages du Lido du petit et du grand travers travers'},
    {src: 'assets/projects/HistoireDeau/DanseEntreLesEaux/carte-lido-+1m-.jpg', label: `Carte des paysages du Lido du petit et du grand travers avec simulation d'une montée des eaux de +1m`},
    {src: 'assets/projects/HistoireDeau/DanseEntreLesEaux/trait-de-cote-63-ojd.jpg', label: `Photograpohie aérienne montrant l'évolution du trait de côte`}],
  text: `Vivre un paysage à risque et être exposé aux risques de submersions marines. C’est d’ores et déjà le cas d’environ 1,5 million d’habitants en France or si aujourd’hui 12% de la population se concentre sur les façades maritimes, d’ici 2040 c’est 40% de la population qui pourrait être hébergée sur ces territoires selon l’INSEE. Au grès des tempêtes le littoral est rongé et recule parfois de plusieurs mètres en un seul hiver, rendant plus crucial le regard que notre profession doit porter sur ces paysages.
 Le temps du mémoire permet de s’inscrire dans cette urgence, dans cette fragilité mais avec un regard non contraint par une vision politique ou par un objectif de projet. A travers cette réflexion il s’agit de comprendre et de révéler la notion de risque à travers le projet de paysage. De la Grande Motte au lido du petit et du grand travers, comment le risque dessinet-il le projet et comment le projet dessine-til le risque ? Quels éléments reflètent la prise en compte de ces aléas et enfin comment montrer la fragilité de ces lieux mouvants ? Les risques font partie du paysage et sont porteurs de projets. Ils réunissent, dispersent ou emportent, mais sans risques il n’y a pas de contraintes, et sans contraintes il n’y a pas de projet.`
};

export const entreTerreEtMer = {
  images: [
    {src: 'assets/projects/HistoireDeau/EntreTerreEtMer/groupe-etude-territoire.jpg', label: 'Carte des paysages réalisée en trinome aux crayons de couleur'},
    {src: 'assets/projects/HistoireDeau/EntreTerreEtMer/carte-relief.jpg', label: 'Carte des reliefs'},
    {src: 'assets/projects/HistoireDeau/EntreTerreEtMer/crobart.jpg', label: 'Croquis du havre de Blainville-sur-mer'},
  ],
  text: `Ce mémoire a pour objet d’étude le canton de Saint-Malo-de-la-Lande en Normandie. Il s’insère dans le programme pédagogique de deuxième année à l’école du paysage de Versailles. Il s’agit dans cet exercice de rendre compte au travers d’une pluralité d’approches (paysagère, sociale, historique, politique...) des spécificités d’un territoire. 
 
 L’étude faite en groupe ne visait cependant pas à donner une définition complète du territoire, mais plutôt à le questionner afin de déceler ce qui le modèle, lui donne vie et engendre ses mutations. Comprendre le littoral et son lien avec les terres, la succession de paysages depuis l’océan jusqu’aux bocages, les influences croisées, ce n’est pas seulement lire le paysage, c’est déjà soulever des axes de projet.
 « Ce n’est pas un paysage qui brille par sa beauté éclatante, mais par son charme profond » Yves MICHEL, Maire de Tourville sur Sienne`
};


export const estranDeLaSeine = {
  images: [
    {src: 'assets/projects/HistoireDeau/EstranDeLaSeine/logo.jpg', label: "Carte topographique du site d'étude"},
    {src: 'assets/projects/HistoireDeau/EstranDeLaSeine/schema-concept.jpg', label: "Schéma conceptuel issu d'une maquette de site réalisée en papier aluminium"},
    {src: 'assets/projects/HistoireDeau/EstranDeLaSeine/schema-nivellement.jpg', label: 'Plan de nivellement après interprétation du premier schéma'},
    {src: 'assets/projects/HistoireDeau/EstranDeLaSeine/schema-topo.jpg', label: 'Carte topographie, traduction par ombrage du plan de nivellement'}
  ],
  text:`Créer un quartier par le paysage à Verneuil-sur-Seine. L'atelier pose la question de comment anticiper l'arrivée d'un nouveau programme d'habitation sur un ancien site industriel en bord de Seine.
L'objectif premier est de concevoir une structure paysagère urbaine, sorte d'armature donnant un statut aux espaces publics/semi-publics/privatifs.
Le second est de gérer le long terme, comment la structure paysagère proposée peut être associée à une souplesse d'utilisation et de programme.
Si la difficulté du site est donné par l'isolement du futur quartier dans la commune (terrain de 20 ha mis à distance du reste de la commune par la voie ferrée Paris-Rouen), le fondement du projet se base sur les phénomènes de marrées et notamment sur le recul des eaux et les motifs qu'elles dessinent sur l'estran.
A l'image de la temporalité longue dans laquelle le projet s'inscrit, la conception est un phasage se basant sur le socle du site laissant la possibilité d'adaptibilité des programmes urbains.`
};
export const sphagnaceae = {
  images: [
    {src: 'assets/projects/HistoireDeau/SphagnaceaePlateauSaclay/cadastre-topo.jpg', label: 'Carte cadastrale et topographique du plateau de Saclay'},
    {src: 'assets/projects/HistoireDeau/SphagnaceaePlateauSaclay/projet.jpg', label: 'Carte des bassins versants et des zones inondables du plateau de Saclay'},
    {src: 'assets/projects/HistoireDeau/SphagnaceaePlateauSaclay/maquette.jpg', label: 'Maquette projet'}
  ],
  text:`Dans le cadre d’un atelier portant sur la transition énergétique, le projet Sphagnaceae débute sur l’hypothèse du changement climatique d’ici à 2050. Aujourd’hui sur le plateau de Saclay au Sud-Ouest de Paris, les pluies sont constantes sur l’année et supérieures à l’évapotranspiration du plateau. En revanche d’ici 2050 le climat va hypothétiquement changer avec des écarts de température de +2°C, des pluies moins bien réparties sur l’année et une évapotranspiration plus élevée. Ces différences entraînent des changements majeurs tels que des pics de sécheresses estivales ou des risques d’inondations plus fréquents en hiver.
  
L’objectif, comme la sphaigne, est d’absorber le surplus d’eau très rapidement, et de le relâcher par la suite plus lentement, conservant ainsi un débit de fuite acceptable de façon à prémunir les vallées des inondations. Pour cela il faut passer par la création : -d’une armature hydraulique solide -d’un accompagnement de ce réseau par une couverture végétale réfléchie -de liens marqués entre plateau et vallée -penser les futures accroches urbaines du plateau « Comme les tumeurs cancéreuses qui ont besoin de néo-vaisseaux pour se développer, les villes ont besoins de leurs réseaux de transports. On a voulu vous faire croire que la ligne 18, un campus de 7 kilomètres linéaire, et un budget de 9 milliards seraient tenus par un décret, et une lisière dessinée. » Extrait de «Je me rappelle Ulcos II» écrit dans le cadre de l’atelier.`
};

export const histoireDeau = [
  danseEntreLesEaux, entreTerreEtMer, estranDeLaSeine, sphagnaceae
];

export const boisDeLibremont = {
  images: [
    {
      src: 'assets/projects/ConduireVivant/BoisDeLibremont_EvidenceDuTerrain/nancy-metropole-limites-entites-paysage.jpg',
      label: 'Nancy metropole limites entités paysage'
    },
    {src: 'assets/projects/ConduireVivant/BoisDeLibremont_EvidenceDuTerrain/montage-debut-pass.jpg', label: "Croquis d'étude, chemin creux"},
    {src: 'assets/projects/ConduireVivant/BoisDeLibremont_EvidenceDuTerrain/belvedere-prairie.jpg', label: "Croquis d'étude, vue sur Nancy"},
    {src: 'assets/projects/ConduireVivant/BoisDeLibremont_EvidenceDuTerrain/entree-voie-verte.jpg', label: "Croquis d'étude, entrée du bois de Libremont"},
    {src: 'assets/projects/ConduireVivant/BoisDeLibremont_EvidenceDuTerrain/picture.jpg', label: 'Premier essai in-situ pour marquer le passage de la voie verte'}
  ],
  text:`La commande initiale émise par la Communauté Urbaine du Grand Nancy (CUGN) était en réalité double. Double concernant les sites, mais double aussi dans la temporalité de réalisation. La première se portait sur la réalisation d’une section de voie verte sur le bois de Libremont, qui à terme se raccordera à une voie bien plus grande traversant les côteaux de Nancy. Cette section doit être l’occasion de réunir les différents quartiers alentours autour d’aménagements sportifs et ludiques qui agrémentent la voie verte. La réalisation envisagée autour de 2019 menait notamment à «l’urgence» du dessin mais porte encore aujourd’hui la question du foncier et des limites du parc. 
 La seconde commande quant à elle se porte sur une échelle de temps beaucoup plus longue, où la réalisation ne s’envisage pas avant 2030. Elle concerne le parc de l’Abiétinée, ancienne pépinière laissée à l’abandon durant de nombreuses années et regorgeant de variétés horticoles qui ont évoluées dans un cadre forestier, fait surprenant et intéressant quand nous connaissons notamment ces espèces en plantations isolées dans des parcs botaniques. Ici l’objectif était d’amorcer une réflexion sur les volumes, les masses, les cheminements, les points d’intérets afin d’aiguiller des pistes de projets pour l’avenir de ce parc qui se veut semi ouvert contrairement au bois de Libremont. Bien que les commandes soient distinctes, apparait une question évidente de mise en relation de ces deux parcs en contact, quelles interractions possibles et   imaginables.`
};


export const sentierInterpretation = {
  images: [
    {src: 'assets/projects/ConduireVivant/SentierInterprétation_MontBrouilly/at3-carte.jpg', label: "Carte du Mont Brouilly et du sentier d'interprétation"},
    {src: 'assets/projects/ConduireVivant/SentierInterprétation_MontBrouilly/picture1.jpg', label: "Réalisation d'un escalier dans la pente"},
    {src: 'assets/projects/ConduireVivant/SentierInterprétation_MontBrouilly/picture2.jpg', label: "Ouverture du sous bois et constitution d'une ramée avec les rémanents"},
  ],
  text: `Repris suite à l’initiative d’un ancien étudiant, Samuel Auray (sorti en 2011 de l’ENSP Versailles), cet atelier s’inscrit comme un moment particulier dans l’apport des idées, dans les échanges avec les habitants, les réalisations, et les essais.
 La dynamique de développement local était à concrétiser avec des solutions d’aménagement concrètes et singulières visant la simplicité des réalisations puis de l’entretien, l’économie, et l’utilisation de ressources en place.
 C’est donc à travers un chantier expérimental et participatif que le projet a pris forme. Le projet de mise en valeur du Mont Brouilly s’inscrit dans la durée et modifie le mode d’intervention du concepteur qui adopte une attitude de projet jardinière.
 Chaque année la gestion du site naturel, génératrice de matière, est ainsi l’occasion de nouveaux chantiers collaboratifs permettant de réajuster la composition initiale, de renouveller des ouvrages éphémères et d’animer le site. Le CAUE du Rhône a été intéressé par cette démarche qu’il a cherché à promouvoir à travers un film qui a été tourné à différents moments de l’atelier.
`
};


export const conduireLeVivant = [
  boisDeLibremont, sentierInterpretation
];


export const aquabulleBD = {
  images: [
    {src: 'assets/projects/Aquabulle/BD/logo.jpg', label: 'Logo'},
    {src: 'assets/projects/Aquabulle/BD/2.jpg', label: 'Picture 2'},
    {src: 'assets/projects/Aquabulle/BD/affiche.jpg', label: 'Affiche'},
    {src: 'assets/projects/Aquabulle/BD/bulle-3.jpg', label: 'Bulle 3'},
    {src: 'assets/projects/Aquabulle/BD/bulle-355.jpg', label: 'Bulle 355'},
    {src: 'assets/projects/Aquabulle/BD/gains-sur-la-mer.jpg', label: 'Gain sur la mer'},
    {src: 'assets/projects/Aquabulle/BD/larvotto.jpg', label: 'Lavotto'},
    {src: 'assets/projects/Aquabulle/BD/nature-des-fonds.jpg', label: 'Nature des fonds'},
    {src: 'assets/projects/Aquabulle/BD/topo-fine-et-fonds-marins.jpg', label: 'Topo fine et fonds marins'},
    {src: 'assets/projects/Aquabulle/BD/zones-bilogiques.jpg', label: 'Zones bilogiques'},
    {src: 'assets/projects/Aquabulle/BD/etoile-affiche.jpg', label: 'Etoile affiche'}
  ],
  text: `Entre la mer Méditerranée et les Alpes, la Principauté de Monaco s’étale bien difficilement, même baignée par un soleil presque omniprésent. enclavée par les frontières françaises il faut longtemps jouer des coudes pour qu’une nouvelle construction fasse jour -un peu à la manière des baigneurs un 15 août sur la plage du Larvotto-. Alors à moins de 20 kilomètres de Nice, sur la côte d’Azur, un petit Manhattan s’élève, comme un champ de tournesols. Alors on s’est (sur)pris à rêver, rêver d’autre chose qu’un mur faisant face à sa terrasse du 25 ième étage. Parce qu’à la base, ici, les tournesols cherchent d’abord la mer...
 Mais quel est donc cette bizarrerie ? ce drôle de paysage ? Un jeu d’étages, un jeu d’étirements, un paysage en ponctuation qui forme un tout, ou l’on baigne accrochés à rien -ou si peu-. Ce n’est pas le miroir de Monaco sous l’eau, mais bien l’autre pendant, celui qui équilibre et rend Monaco résiliente -autant que possible- en portant une réflexion sur un mode de construction créatif...
 
 «La ruée sur l’eau, la rue est sur l’eau, la rue est sous l’eau, la ruée sous l’eau.» Extrait de «Aquabulle» bande dessinée réalisée dans le cadre du TPFE (Travail Personnel de Fin d’études). `
};

export const aquabulle = [
  aquabulleBD
];

export const pailloteFleurie = {
  images: [
    {src: 'assets/projects/JardinsDeMalachite/PailloteFleurie/croquis.jpg', label: 'Croquis'},
	{src: 'assets/projects/JardinsDeMalachite/PailloteFleurie/picture2.jpg', label: 'Etat des lieux'},
    {src: 'assets/projects/JardinsDeMalachite/PailloteFleurie/croquis2.jpg', label: 'Croquis'},
    {src: 'assets/projects/JardinsDeMalachite/PailloteFleurie/picture1.jpg', label: 'Etat des lieux'},
    {src: 'assets/projects/JardinsDeMalachite/PailloteFleurie/picture3.jpg', label: 'Réalisation côté bassin'},
    {src: 'assets/projects/JardinsDeMalachite/PailloteFleurie/picture4.jpg', label: 'Réalisation côté allée'},
  ],
  text: `Une allée désespérement vide, une entrée sur un gazon chétif, et pourtant une paillote faite maison émerge et, avec son bassin atenant, méritent d'êtres présentés en douceur, comme un spectacle en plusieurs scènes qui se dévoilent lentement.

Dynamiser l'entrée du jardin, masquer le bassin et se laisser guider par le bruit régulier de l'eau qui s'écoule. L'épaisseur du massif permet une concentration de plantes à fleurs pour offrir depuis le salon et la terrasse un effet somptueux du printemps à l'automne.
Et finalement cette allée qui invite à poursuivre plus à l'intérieur du jardin, scénarise l'entrée, et définie l'espace dont la lecture était jusque là bien floue.
`
};

export const coconDu7ieme = {
  images: [
    {src: 'assets/projects/JardinsDeMalachite/CoconDu7ieme/picture3.jpg', label: 'Croquis'},
    {src: 'assets/projects/JardinsDeMalachite/CoconDu7ieme/picture2.jpg', label: 'Etat des lieux'},
    {src: 'assets/projects/JardinsDeMalachite/CoconDu7ieme/picture4.jpg', label: 'Croquis'},
	{src: 'assets/projects/JardinsDeMalachite/CoconDu7ieme/picture1.jpg', label: 'Etat des lieux'},
    {src: 'assets/projects/JardinsDeMalachite/CoconDu7ieme/picture5.jpg', label: 'Réalisation coin salon'},
    {src: 'assets/projects/JardinsDeMalachite/CoconDu7ieme/picture6.jpg', label: 'Réalisation terrasse'},
  ],
  text:`De grands murs tristes, une terrasse vide exposée aux vents, il y à tant à voir aux alentours mais le manque de cadre ne permet pas de poser le regard sur l'essentiel.
Et pourtant de suite, cet espace me fascine.

L'idée structurante m'apparaît rapidement, dessiner deux espaces distincts en lien avec les accès sur la terrasse (chambre et bibliothèque) qui cadrent le paysage alentour (lac et coteau boisé).
Pour cela, je positionne de grands bacs en bois qui créent naturellement de nouvelles pièces extérieures et qui seront supports d'une végétation dense -antichambre et cocon verdoyants-. 
Après quelques mois les plantes sont surprenantes de vitalité, vivement les visites futures pour voir ce jardin s'épanouir.
`

};


export const jardinsMalachite = [
  pailloteFleurie, coconDu7ieme
];
