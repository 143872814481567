import { Component, OnInit } from '@angular/core';
import {dev} from '../../constante';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {

  constStrings = dev;
  constructor() { }

  ngOnInit() {
  }

}
