import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'close-cross',
  templateUrl: './close-cross.component.html',
  styleUrls: ['./close-cross.component.css']
})
export class CloseCrossComponent implements OnInit {

  @Output()
  onclick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
