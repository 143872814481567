import {Component, OnInit} from '@angular/core';
import {slide} from '../../animations/animations';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css'],
  animations: [slide]
})
export class DrawerComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  closeNav() {
    document.getElementById('drawer').style.width = '0';
    document.getElementById('drawer').style.opacity = '0';
    document.getElementById('drawer').style.color = 'black';

  }

}
