import { Component, OnInit } from '@angular/core';
import {contact, error, success} from '../../constante';
import {EmailService} from '../../service/email/email.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contact = contact;
  constError = error;
  constSuccess = success;

  constructor(private emailService: EmailService, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  submitMail() {
    const data = this.getFormData();
    if (data['greenLand']) {  // if form is filled, form will not be submitted because this fill need to be empty
      this.openSnackBarError(this.constError.error);
      return false;
    }else {
      if (this.emailService.sendEmail(data)) {
        document.getElementsByName('message')[0]['value'] = '';
        document.getElementsByName('email')[0]['value'] = '';
        this.openSnackBarSuccess(this.constSuccess.email);
      } else {
        this.openSnackBarError(this.constError.email);
      }
    }
  }

  getFormData() {
    const form = document.getElementById('gform');
    const elements = form['elements']; // all form elements
    const fields = Object.keys(elements).map(function(k) {
      if (elements[k].name !== undefined) {
        return elements[k].name;
        // special case for Edge's html collection
      }else if (elements[k].length > 0) {
        return elements[k].item(0).name;
      }
    }).filter(function(item, pos, self) {
      return self.indexOf(item) === pos && item;
    });
    const data = {formDataNameOrder: {}, formGoogleSheetName: '', formGoogleSendEmail: ''};
    fields.forEach(function(k){
      data[k] = elements[k].value;
      let str = ''; // declare empty string outside of loop to allow
                    // it to be appended to for each item in the loop
      if (elements[k].length) {
        for (let i = 0; i < elements[k].length; i++) {
          if (elements[k].item(i).checked) {
            str = str + elements[k].item(i).value + ', '; // same as above
            data[k] = str.slice(0, -2);
          }
        }
      }
    });

    // add form-specific values into the data
    data.formDataNameOrder = JSON.stringify(fields);
    data.formGoogleSheetName = form.dataset.sheet || 'responses'; // default sheet name
    data.formGoogleSendEmail = form.dataset.email || ''; // no email by default

    return data;
  }

  openSnackBarError(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['snack-bar-custom-error'];
    config.duration =  3000;
    this.snackBar.open(message, '', config);
 }

  openSnackBarSuccess(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['snack-bar-custom-success'];
    config.duration =  3000;
    this.snackBar.open(message, '', config);
  }


}
