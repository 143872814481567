import {Component, HostBinding, OnInit} from '@angular/core';
import {ConstanteName} from '../../constante';
import {fadeAnimation, slideInOutAnimationTop} from '../../animations/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [fadeAnimation, slideInOutAnimationTop],
})
export class HomeComponent implements OnInit {
  @HostBinding('@slideInOutAnimationTop') slideInOutAnimationTop = true;


  constName = ConstanteName;
  name = this.constName.firstName + ' ' + this.constName.lastName;

  constructor() { }

  ngOnInit() {
  }

}
