// import the required animation functions from the angular animations module
import {trigger, state, animate, transition, style, query, group, animation, animateChild} from '@angular/animations';

export const slideInOutAnimationTop =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideInOutAnimationTop', [

    // end state styles for route container (host)
    state('*', style({
      // the view covers the whole screen with a semi tranparent background
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    })),

    // route 'leave' transition
    transition(':leave', [
      group([
        query('img', [
          animate('1.5s ease-in-out', style({transform: 'scale(0.75)'}))
        ]),
        query('.content', [
          // animation and styles at end of transition
          style({position: 'fixed', top: 0}),
          animate('2.5s ease-in-out', style({
            // transition the right position to -400% which slides the content out of view
            top: '-100%'
            // transition the background opacity to 0 to fade it out
          }))
        ])
      ])
    ])
  ]);


export let fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    // remember that :enter is a special
    // selector that will return each
    // newly inserted node in the ngFor list
    query('*', [
      style({opacity: 0}),
      animate('2.5s ease-in-out', style({opacity: 1}))
    ])
  ])
]);



export let fadeOutAnimation = trigger('fadeOutAnimation', [
  transition(':leave', [
    // remember that :enter is a special
    // selector that will return each
    // newly inserted node in the ngFor list
    query('*', [
      style({opacity: 1}),
      animate('2.5s ease-in-out', style({opacity: 0}))
    ])
  ])
]);




export let fadeAnimationQuick = trigger('fadeAnimationQuick', [
  transition(':enter', [
    // remember that :enter is a special
    // selector that will return each
    // newly inserted node in the ngFor list
    query('*', [
      style({opacity: 0}),
      animate('0.5s ease-in-out', style({opacity: 1}))
    ])
  ])
]);

export let fadeOutAnimationQuick = trigger('fadeOutAnimationQuick', [
  transition(':leave', [
    // remember that :enter is a special
    // selector that will return each
    // newly inserted node in the ngFor list
    query('*', [
      style({opacity: 1}),
      animate('0.5s ease-in-out', style({opacity: 0}))
    ])
  ])
]);

export let zoomAndFade = [
  group([
    query(':leave', [
      style({
        opacity: 1, position: 'fixed',
        top: 0, bottom: 0, right: 0, left: 0}),
      animate('2s ease-in-out', style({opacity: 0, transform: 'scale(1.5)'}))
    ], {optional: true}),
    query(':enter', [
      style({opacity: 0, position: 'fixed',
        top: 0, bottom: 0, right: 0, left: 0}),
      animate('2.5s ease-in-out', style({opacity: 1}))
    ], {optional: true})])
];

export let slide = trigger('slide', [
  state('*', style({
    // the view covers the whole screen with a semi tranparent background
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  })),
  transition(':enter', [
    style({width: '0%'}),
    query('*', [
      animate('1s ease-in-out', style({
        width: '100%'
      }))
    ], {optional: true})
  ])
]);

export let routerTransition = trigger('routerTransition', [
  transition('chapter1 => chapter2', zoomAndFade),
  transition('chapter2 => contact', zoomAndFade),

]);
